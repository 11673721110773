<script setup lang="ts">
const { t } = useT();
const route = useRoute();
const isGuest = useIsGuest();
const [expanded, toggleExpanded] = useToggle();
const { handleSupportButtonClick } = useSupportChat();
const { data: appInitData } = useAppInitData();
const { scrollToContent, content, offset } = useFeedbackForm();
const { feedback } = route.query;

const aboutText = [
	"NO PURCHASE IS NECESSARY to enter free game promotion (“promotional games”). PROMOTIONAL GAMES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see Terms of Use.",
	"NoLimitCoins Social Gaming Platform is a play-for-fun website intended for amusement purposes only. NoLimitCoins Social Gaming Platform does not offer “real-money gambling” or opportunity to win real money based on a gameplay.",
	"NoLimitCoins Social Gaming Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry."
];
const { data: homeData } = useGetHomePageData();
const seoText = computed(() => homeData.value?.seo?.seoText);
const hasDeposits = computed(() => appInitData.value?.hasDeposits);
const isShowAffiliate = computed(() => !isGuest.value && appInitData.value?.isAffiliate);

const filterFooterLinks = (slug: string) => {
	const rules = [
		{ slugs: ["alternative-method-of-entry"], condition: () => isGuest.value },
		{ slugs: ["affiliate-agreement", "partners-rules"], condition: () => !isShowAffiliate.value },
		{ slugs: ["promotional-games-rules", "refund-policy"], condition: () => !hasDeposits.value }
	];

	return !rules.some((rule) => rule.slugs.includes(slug) && rule.condition());
};

const termsLinks = computed(() =>
	appInitData.value?.footer?.menu
		?.find((item) => item?.title === "second_column")
		?.items?.filter((link) => link?.slug && filterFooterLinks(link?.slug))
);

const handleScroll = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth"
	});
};

onMounted(() => {
	if (feedback) {
		setTimeout(() => {
			scrollToContent(offset.value);
		}, 50);
	}
});
</script>

<template>
	<footer>
		<AButton class="button-top" variant="secondary" data-tid="jump-to-top" @click="handleScroll">
			<NuxtIcon name="16/arrow-top" filled />
			<AText :modifiers="['uppercase']">{{ t("Take me up") }}</AText>
		</AButton>
		<section class="grid">
			<div class="help">
				<AText class="title" variant="toledo" :modifiers="['bold', 'uppercase']" as="div">{{ t("need help") }}?</AText>
				<div class="links-contact">
					<NuxtIcon name="20/chat" filled />
					<AText :modifiers="['link', 'underline']" data-tid="footer-support" @click="handleSupportButtonClick()">{{
						t("Online support")
					}}</AText>
				</div>
				<NuxtLink to="mailto:support@nolimitcoins.com" external class="links-contact" data-tid="footer-support-email">
					<NuxtIcon name="20/mail" filled />
					<AText :modifiers="['link', 'underline']">support@nolimitcoins.com</AText>
				</NuxtLink>

				<AText class="help-text" as="div">{{ t("US Payment Related Queries (24/7):") }}</AText>

				<NuxtLink to="tel:+12089745349" external class="links-contact" data-tid="footer-support-phone">
					<NuxtIcon name="20/phone" filled />
					<AText :modifiers="['link', 'underline']">{{ t("+1 (208) 974-5349") }}</AText>
				</NuxtLink>
				<ClientOnly>
					<div v-if="appInitData?.socialNetworks?.length" class="links-social">
						<NuxtLink
							v-for="social in appInitData?.socialNetworks"
							:key="social?.id"
							:to="social?.url"
							:data-tid="`footer-social-${social?.id}`"
							external
							target="_blank"
						>
							<span v-html="social?.icon" />
						</NuxtLink>
					</div>
				</ClientOnly>
			</div>
			<div class="description">
				<div class="icon">
					<NuxtLink
						class="icon-trustpilot"
						data-tid="footer-trustpilot"
						to="https://www.trustpilot.com/evaluate/nolimitcoins.com?stars=5"
						external
						target="_blank"
					>
						<NuxtImg
							src="/nuxt-img/footer/btn_trustpilot.svg"
							loading="lazy"
							alt="trustpilot"
							width="110"
							height="40"
						/>
					</NuxtLink>
					<NuxtImg src="/nuxt-img/footer/18.svg" loading="lazy" alt="age 18+" width="32" height="32" />
				</div>
				<div class="about">
					<AText class="title" variant="toledo" data-tid="footer-about" :modifiers="['bold', 'uppercase']" as="div">{{
						t("about nolimitcoins")
					}}</AText>
					<AText v-for="(text, index) in aboutText" :key="index" as="p">{{ t(text) }}</AText>
				</div>
				<div v-if="route.path === '/'" class="seo" data-tid="footer-seo">
					<ACollapse :expanded="expanded">
						<template #title>
							<div :class="['seo-button', { active: expanded }]" @click="toggleExpanded()">
								<AText variant="toledo" :modifiers="['bold']">{{
									t("NoLimitCoins: Free to Play Social Casino")
								}}</AText>
								<NuxtIcon name="16/arrow-down-small" filled />
							</div>
						</template>
						<template #expanded>
							<div class="seo-text" v-html="seoText" />
						</template>
					</ACollapse>
				</div>
			</div>

			<div ref="content" class="feedback" data-tid="footer-feedback">
				<ClientOnly>
					<LazyOFeedback />
				</ClientOnly>
			</div>
		</section>
		<section class="terms" data-tid="footer-terms">
			<NuxtImg
				class="logo"
				src="/nuxt-img/footer/logo-footer.png"
				loading="lazy"
				alt="logo"
				width="160"
				height="35"
				format="webp"
			/>
			<div class="terms-flex">
				<NuxtLink
					v-for="(link, index) in termsLinks"
					:key="link.slug"
					class="links"
					:data-tid="`footer-terms-${index}`"
					:to="`/page${link?.url}`"
					external
				>
					<AText as="div">{{ link.title }}</AText>
				</NuxtLink>
			</div>
		</section>
		<AText class="copyright" data-tid="footer-copyright" :modifiers="['center']" as="div"
			>{{ appInitData?.footer?.copyright }} {{ t("NoLimitCoins Social Gaming Platform") }}</AText
		>
	</footer>
</template>

<style lang="scss" scoped>
footer {
	background: var(--a-header-background);
	backdrop-filter: var(--a-header-backdrop-filter);
	padding: gutter(3) gutter(4);
	@include media-breakpoint-down(lg) {
		padding: gutter(3) gutter(2);
		margin-bottom: gutter(7);
	}
	.button-top {
		@include media-breakpoint-down(lg) {
			margin: 0 auto gutter(3);
		}
		@include media-breakpoint-up(lg) {
			margin: 0 0 gutter(2) auto;
		}
		.nuxt-icon {
			margin-right: gutter(1.5);
		}
	}
	.title {
		margin-bottom: gutter(3);
	}
	p {
		line-height: 22px;
		margin-bottom: gutter(2);
	}

	.grid {
		@include media-breakpoint-down(lg) {
			margin-bottom: gutter(2);
			display: flex;
			flex-direction: column;

			> * {
				order: 3;
			}

			> .feedback {
				order: 2;
			}
		}
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-gap: gutter(2);
			grid-template-areas: "help description feedback";
			margin-bottom: gutter(3);
		}

		.description {
			display: flex;
			flex-direction: column;
			gap: gutter(2);

			@include media-breakpoint-up(lg) {
				> * {
					order: 2;
				}
				.about {
					order: 1;
				}
			}
		}
		.help {
			grid-area: help;
			@include media-breakpoint-up(lg) {
				width: 216px;
			}
			&-text {
				padding: gutter(1.25) 0 gutter(0.5);
			}
			.links-contact {
				display: flex;
				align-items: center;
				margin-bottom: gutter(2);
				transition: all 200ms ease-in-out;
				:hover {
					text-decoration: none;
					opacity: 0.85;
				}
				.nuxt-icon {
					font-size: 20px;
					margin-right: gutter(1);
				}
			}
		}
		.links-social {
			display: flex;
			gap: gutter(1.5);
			margin: gutter(3.375) 0 gutter(2);
			a {
				transition: all 200ms ease-in-out;
				&:hover {
					opacity: 0.85;
				}
			}
		}
		.about {
			grid-area: about;
			@include media-breakpoint-down(lg) {
				margin-top: gutter(5);
			}
		}
		.icon {
			grid-area: icons;
			@include media-breakpoint-down(lg) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			@include media-breakpoint-up(lg) {
				text-align: right;
			}
			&-trustpilot {
				margin-right: gutter(8.125);
			}
		}
		.seo {
			grid-area: seo;
			padding: gutter(2) 0;
			&-button {
				display: flex;
				align-items: center;
				letter-spacing: -0.01962em;
				cursor: pointer;
				.nuxt-icon {
					display: inline-block;
					font-size: 24px;
					margin-left: gutter(1.875);
					transition: all 200ms ease-in-out;
				}
				&.active .nuxt-icon {
					transform: rotate(180deg);
				}
			}
			&-text {
				color: var(--custom-rgb-4);
				font-size: 14px;
				line-height: 1.35;
				font-weight: 300;
				margin-top: gutter(1);
				&:deep() {
					h2 {
						font-size: 28px;
						line-height: 1.167;
					}
					h3 {
						font-size: 24px;
						line-height: 1;
					}
					:is(h2, h3) {
						color: var(--neutral-100);
						font-weight: 500;
						margin-bottom: gutter(2.5);
					}
					p {
						text-align: justify;
						line-height: 1.375;
						margin-bottom: gutter(3);
					}
					ul {
						margin: 0 0 gutter(2.5) gutter(1.75);
						li {
							list-style-type: disc;
							line-height: 1.375;
							margin-bottom: gutter(1.25);
						}
					}
				}
			}
		}
	}
	.terms {
		border-top: 1px solid var(--custom-rgb-6);
		border-bottom: 1px solid var(--custom-rgb-6);
		padding: gutter(2.125) 0;
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.logo {
			filter: grayscale(100%);
			@include media-breakpoint-down(lg) {
				display: block;
				margin: 0 auto;
			}
		}
		&-flex {
			@include media-breakpoint-up(lg) {
				display: flex;
				gap: gutter(5);
				margin-left: gutter(9);
			}
			.links {
				display: block;
				color: var(--custom-6);
				@include media-breakpoint-down(lg) {
					margin-top: gutter(2);
				}
				&:hover {
					text-decoration: underline;
					opacity: 0.85;
				}
			}
		}
	}
	.copyright {
		padding-top: gutter(3);
	}
}
</style>
